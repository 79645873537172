import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/SuccessBox';
import VideoTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/videoTable';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Video/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Video Settings",
  "path": "/Web_User_Interface/1080p_Series/Multimedia/Video/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth.",
  "image": "./WebUI_1080p_SearchThumb_Multimedia_Video.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Multimedia_Video.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Multimedia Menu // Video Settings' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth.' image='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Video.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Video.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Multimedia/Video/' locationFR='/fr/Web_User_Interface/1080p_Series/Multimedia/Video/' crumbLabel="Video Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The video settings allow you to adjust the quality of all 3 video streams of your camera. Please be advised that high quality settings come with high requirements for your computer hardware and network bandwidth. Adjust the video quality to obtain a fluent video accordingly. Please refer to `}<a parentName="p" {...{
        "href": "/en/Products/Indoor_Cameras/IN-8015_HD/Video_Streaming/"
      }}>{`RTSP Streaming`}</a>{` for accessing the cameras streams 1 (1080p/1920x1080), 2 (320p/640x352) and 3 (160p/320x176) with third-party software. Be aware that you can set different values for all available streams. The web user interface utilizes the first stream by default (unless switched here). Our mobile apps use the second stream, that has a lower resolution and lower bandwidth requirements.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/19dadf27bdf81f7bcf23629503c24bad/d8104/1080p_Settings_Multimedia_Video.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACwUlEQVQoz22SW0gUURjHpwhCwwdNkgyLBFk0jdZ03S4oeMHosq4KPljW5m1T8dLOzDlz5rI7Z2ZnEx9WohKKEAopIaKihyBquxP2FlFPPWwUPaRG6V5m1909Ma6XoH6vH7/v///gowDLAgAghIjnBQ4wysX+y6/6L73oGn/e6X/W6Q90+gNd/qcDE29c2jWR50TR7XA4SvaUmssrKBZABkAAoap6G+pr2zscczoJ/gh9/vbzy1wsOL8UnIsHZ/X5OLk7PY3OnGY6HJ3HWu3Ww9UlZRTkOJqBAECfplVZD9mb2wgh0fDvRCxCVkkkEoSQmftTV4ecY+ckqakDHqlpNldSiEMMgAwLFNXb2FArcSOEED0aTWupVErX9YWFUJKQBzdunT96yu/sHneO2Oq695VVUSzgaNZIVr1afc3B4Z6ThJB4LLaamgpH9HgsliDkun/8Idv6Qa17P95+T4Z0o4ViAHSla/s0i+WAzdacSqUWFxfXOkdjyXh8iRDy6M6k07a/xVyi2XY96c1/3FVCMSxkWAhh+mZrk91uCJHwWu1IJBIOhQghk1M383YUFheV5ubmF+Xl0JatFL0sAwC8Xq+lymq3Nxu1E8m15HhMX1jeNXZhND93m8lUnJOdnZWZUb87k+IMEABA07SKygrbieNk9l0yGEglDf/T19DLj7++B16TtzPqqC+3IK+gaOeWrIzNmzZYt2+k0DIQQoyxvcm+11wxIXVfcZ/tHRh0M/TtQdrdNzLU04vo4T5hsMXZVt1SZ7aUl5WXFZpMKzJCiOM4j9stSpJxA4AumgYM4zb+zziL4aAkST6fhrGCFQXLsoLxuowQkiRJwVhRVEVRVVVVFAWr62CMPR6PvAzGWJZliucRjxDPr8iyLHv+Gv8XzyqUizF+28VCADlBEDie5xAvCIIoiuld/5qSJImiiBD6A3J7zhwu/6QfAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19dadf27bdf81f7bcf23629503c24bad/e4706/1080p_Settings_Multimedia_Video.avif 230w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/d1af7/1080p_Settings_Multimedia_Video.avif 460w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/7f308/1080p_Settings_Multimedia_Video.avif 920w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/0ec6b/1080p_Settings_Multimedia_Video.avif 1365w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/19dadf27bdf81f7bcf23629503c24bad/a0b58/1080p_Settings_Multimedia_Video.webp 230w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/bc10c/1080p_Settings_Multimedia_Video.webp 460w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/966d8/1080p_Settings_Multimedia_Video.webp 920w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/aca34/1080p_Settings_Multimedia_Video.webp 1365w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/19dadf27bdf81f7bcf23629503c24bad/81c8e/1080p_Settings_Multimedia_Video.png 230w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/08a84/1080p_Settings_Multimedia_Video.png 460w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/c0255/1080p_Settings_Multimedia_Video.png 920w", "/en/static/19dadf27bdf81f7bcf23629503c24bad/d8104/1080p_Settings_Multimedia_Video.png 1365w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/19dadf27bdf81f7bcf23629503c24bad/c0255/1080p_Settings_Multimedia_Video.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Video",
              "title": "Web User Interface - 1080p Series - Multimedia Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VideoTable mdxType="VideoTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Known Issues:`}</p>
    <ul>
      <li parentName="ul">{`The HTML5 Video script supports `}<strong parentName="li">{`Chrome`}</strong>{`, `}<strong parentName="li">{`Firefox`}</strong>{` and `}<strong parentName="li">{`Safari`}</strong>{`. `}<strong parentName="li">{`Microsoft Edge`}</strong>{` and `}<strong parentName="li">{`Microsoft Internet Explorer`}</strong>{` are not supported. But Microsoft Edge was recently replaced by Microsoft with a Chrome-based Browser. The name Edge remained, but this version `}<strong parentName="li">{`is compatible with HTML5 Video`}</strong>{` and you will have audio as well.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`Safari`}</strong>{` browser does not support audio when you activate `}<strong parentName="li">{`HTML5 Video`}</strong>{`. You can load the WebUI in Firefox or Chrome if you want to hear the audio from your camera.`}</li>
      <li parentName="ul">{`The HTML5 video stream can only be accessed via HTTP. With HTTPS, the MJPEG stream is used in medium resolution.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "911px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/636c2/HTML5_Video_Resolution.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz1WQXU/aYBiGOdUV6+aC+2B8KCD9ikALOvopnZQiSN/3eWtb26LbPDKZS5RET0THD/DIxL+7VMbmrtwHz8mV+86T4nme5/gtdssoGyRHhpnhwZsDi7Ys2urRvf/yugefIKyHhXwhm83mcrmUIAjbwnaJLZ0Kp4/C4ywzm1LTKXV3l76fUnfPd5Lb5dtZZvZQephsTPLZfHGjWNospViW5TiOYRhVUdEhcg4dZ+QcEff76dcj4iIHucQlQDDCaIQIIta+lVnPpNNpml5J8c8wVUY39CAMvMAjLvEBHxPwCZAjggE7yBmh0fBwOBgO9q3u2tpbiqJWV+lEnjfruh7HcRiGAIAwngcA+v2+pmntdluWZUVR9jqdFZpeWl768PH9H7larWqaNh6PoygCALwAAAaDQbfbNU1TURTDMDpm51WaWn+3Xt8WUhzHJbOfm6MoCoIAY4wQeilblmWapizLuq7btt1o1CSpzvNsIs9na5p2cnIShqHv+67rzn0AsHs9XdNVRZVEcae1Y9s9Wd5tNsWmJKYYhnnZHEXReDyO49j3fIyxDeCfnV1cT35cX51f/by4mYwwbtRrjUa9VqslsiAI82bXdf+uheTJ+Kb75VzpgxxBK4BWaAt4f69b2apUypVyufxPNgzD8zwAcBeAR55aB0/Fb+f5y8tsfF8+/lU8Rnovv1ks5AuFQiGRWZZlGEYURU3TVFWVZfnzgpqsirLSajd31WZblxSjJUliccFvD9O3BY5iNbIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc721d23d8e830ce8ba99aef6dc09c43/e4706/HTML5_Video_Resolution.avif 230w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/d1af7/HTML5_Video_Resolution.avif 460w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/e0958/HTML5_Video_Resolution.avif 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bc721d23d8e830ce8ba99aef6dc09c43/a0b58/HTML5_Video_Resolution.webp 230w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/bc10c/HTML5_Video_Resolution.webp 460w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/19a92/HTML5_Video_Resolution.webp 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bc721d23d8e830ce8ba99aef6dc09c43/81c8e/HTML5_Video_Resolution.png 230w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/08a84/HTML5_Video_Resolution.png 460w", "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/636c2/HTML5_Video_Resolution.png 911w"],
              "sizes": "(max-width: 911px) 100vw, 911px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bc721d23d8e830ce8ba99aef6dc09c43/636c2/HTML5_Video_Resolution.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Video",
              "title": "Web User Interface - 1080p Series - Multimedia Video",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      